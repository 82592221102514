.navBar {
  font-size: 20px;
}

.navBarItems {
  color: rgb(255, 255, 255) !important;
}

.needhelp {
    color: white;
    font-size: 1.1rem;
}

.navBarItems {
  margin-left: 1rem;
  color: rgb(255, 255, 255) !important;
}

.navBarItems:hover {
  text-decoration: none;
}

.navBarItems.border {
  border-width: 2px;
  border-style: solid;
  border-color: rgb(255, 255, 255);
  border-radius: 5px;
  background-color: white;
  color: #5e72e4 !important;
  font-weight: 600;
}

.banner {
  padding-bottom: 5rem;
  width: 100%;
  background-image: linear-gradient(134deg, #5e72e4, #835fe4);
}

.banner .logo {
    text-align: center;
}

.pricing {
  padding: 10%;
  width: 100%;
  text-align: center;
  background-image: linear-gradient(134deg, #765ee4, #5f94e4);
}

.bannerBody {
  padding: 4rem;
}

.banner h1 {
  color: white;
  font-size: 2.5rem;
}

.hero {
  border-radius: 10px;
  width: 100%;
}

.iconHero {
  width: 50%;
  margin-bottom: 2rem;
}

.main {
  margin-top: 4rem;
}

.main h2 {
  text-align: center;
}

.featureItem {
  text-align: center;
  margin-bottom: 5rem;
  padding: 0% 5%;
}

.featureItem img {
  margin-bottom: 1rem;
}

.featureHeading {
  font-size: 28px;
  font-weight: 700;
}

.featureDescription {
  color: rgb(114, 114, 114);
  font-size: 20px;
}

.emoji {
    height: 1rem;
}

.features img {
  max-height: 266px;
}

.price {
  font-size: 30px;
  font-weight: 500;
}

.dotpoint {
  list-style: none;
}

.pricingPlan {
  margin-bottom: 1rem;
  min-height: 300px;
}

.pollsBody {
    padding: 0% 5%;
    text-align: center;
    margin-top: 5%;
}

.pollsContainer {
    margin: 0% 5%;
}

.poll {
    padding: 1% 10% 3% 10%;
    margin-bottom: 5%;
    min-height: 16rem;
}

.poll li {
    text-align: left;
    list-style: none;
}

.poll .counter {
    font-weight: 600;
}

.poll .optionVoters {
    color: rgb(133, 133, 133);
    font-size: .9rem;
}

.optionMeta {
    margin-right: .5rem;
}

.currentPlan .card-body {
    border-top: 5px solid rgb(99, 237, 255);
    border-bottom: 5px solid rgb(99, 237, 255);
}

.billing {
    text-align: center;
    background-image: linear-gradient(134deg, #ae21f0, #6921f0);
}

.billing h1 {
    color: white;
}

.billingBody {
    text-align: center;
    padding: 5%;
}

.billingBody .ph1, .billingBody .ph2 {
    color: white;
}

.ph1 {
    margin-bottom: 5vh;
    font-size: 40px;
}

.ph2 {
    margin-bottom: 2vh;
    font-size: 40px;
}

.ph3 {
    color: white;
    margin-bottom: 5vh;
    font-size: 20px;
}

.paymentSettings {
    text-align: center;
    margin-top: 4%;
}

.paymentSettings button {
    text-align: center;
    margin: 0% 2% 2%;
}

.policy {
    margin: 10%;
}

@media screen and (max-width: 600px) {
    .banner {
        text-align: center;
    }

    .banner h1 {
        font-size: 2rem;
    }

    .bannerBody {
      padding: 4rem 1rem 0rem 1rem;
    }
}